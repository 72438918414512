import React, { useState, useEffect } from "react";
import { baseURL, ssoURL } from "../services/Aut.post.js";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./Styles/Spinner.css";
import axios from "axios";
const Identifier = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  // const navigate = useNavigate();
  //check  here for site 
  const next = searchParams.get('next');
  const redirectto = searchParams.get('redirectto');
  const stocken = searchParams.get('stocken');
  // Get Next url to split
  const nexturl = new URL(next);
  const nextbaseUrl = nexturl.origin;
  // const nextpath = nexturl.pathname.substring(1);

//check user is login ornot
useEffect(() => {
  // Simulate data loading with a timeout
  setTimeout(() => {
    setData({ message: "Data loaded!" });
    setLoading(false);
  }, 10000); // 2 seconds loading time
}, []);


useEffect(() => {
  const headers = {
    "Content-Type": "application/json",
    
  };
  const formData = {
    // Your form data goes here
    siteName: "Tockens",
    siteTocken: stocken
  };
  const accessToken = JSON.parse(localStorage.getItem("user"));
  if(accessToken){
    if(accessToken.signup_status === true && accessToken.refresh && accessToken.access){

    }if (next) {
      // const nexturl = new URL(next);
      // const nextbaseUrl = nexturl.origin;

      const sendRequest = async () => {
        try {
          const returnResponse = await axios.post(
            `${baseURL}/userapi/checksitegettocken/`,
            formData,
            { headers: headers }
          );
          if(returnResponse.data.site_verfy === true){
            const url = new URL(next);

            
            const accessTocken = accessToken.access
            const refreshTocken = accessToken.refresh
            url.searchParams.set('token',accessTocken); // Add or update the token parameter
            url.searchParams.set('retoken',refreshTocken);
            url.searchParams.set('redirectto',redirectto);

           
            // Redirect to the modified URL
            window.location.href = url.toString();
          }else{
            //error
          }
        
         
        } catch (error) {
          console.error("Error:", error.response?.data?.message || error.message);
        }
      };

      sendRequest();


    }
    

  }else{

    
    //return false();
   window.location.href = `${ssoURL}/phonesignup?${searchParams}`;
    
   
  }
  


}, []);




  return (
    <div>
    {loading ? (
      <div className="spinner"></div> // Show the spinner while loading
    ) : (
      <div>{data.message}</div> // Show the data once loaded
    )}
  </div>
  );
};

export default Identifier;
