import React, { useState, useEffect, useRef } from 'react';
// import './PuzzleGame.css';
import "./Styles/PuzzleGame.css";
import InnerBanner from "./InnerBanner";
import axios from 'axios';
import {baseURL } from "../services/Aut.post.js";
import ReCAPTCHA from 'react-google-recaptcha';


const handleReloadGane = () => {
    window.location.reload();
  };

const PuzzlePiece = ({ number, onClick }) => {
    return (
        // value-${size}
        <div className={`puzzle-piece`}
        onClick={onClick}>
            {number !== null ? number : ''}
        </div>
    );
};

const PuzzleGame = () => {
   



 

    

    



    const [nooflives, setNooflives] = useState();
   
    
    //const [size, setSize] = useState(Number(accessGrid));
    const [size, setSize] = useState(4);
    const [maxClicks, setMaxClicks] = useState(120);
    const [timeLimit, setTimeLimit] = useState(300);


    
    const [grid, setGrid] = useState([]);
    const [emptyCell, setEmptyCell] = useState({ row: size - 1, col: size - 1 });
    const [clickCount, setClickCount] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState(0);
    
    const [timerRunning, setTimerRunning] = useState(false);
    const intervalRef = useRef(null);
     const recaptchaRef = useRef(null);

 

    
  



  


//show are you sure
useEffect(() => {
    const handleBeforeUnload = (event) => {
        // Cancel the event as returning a string will prompt the user
        event.preventDefault();
        // Chrome requires returnValue to be set
        event.returnValue = '';

        
        // Show the confirmation message
        const confirmationMessage = 'Are you sure you want to exit the game?';
        event.returnValue = confirmationMessage; // For some browsers
        return confirmationMessage;
    };

    const handlePageUnload = () => {
        // Show the confirmation message
        const confirmationMessage = 'Are you sure you want to exit the game?';
        return confirmationMessage;
    };

    const handlePopState = (event) => {
        // Show the confirmation message when navigating back
        if (event.type === 'popstate') {
            const confirmed = window.confirm('Are you sure you want to exit the game?');
            if (confirmed) {
                // Redirect to dashboard page
                // Replace 'dashboard' with your actual dashboard page URL
                window.location.href = '/dashboard';
            } else {
                return false;
                // Prevent navigating back
                //window.history.pushState(null, '', window.location.href);
            }
        }
    };

    // Add event listeners for beforeunload, unload, and popstate events
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handlePageUnload);
    window.addEventListener('popstate', handlePopState);

    // Clean up event listeners on component unmount
    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('unload', handlePageUnload);
        window.removeEventListener('popstate', handlePopState);
    };
}, []);


const handleConfirmExit = () => {
    const confirmed = window.confirm('Are you sure you want to exit the game?');
    if (confirmed) {
        // Redirect to dashboard page
        // Replace 'dashboard' with your actual dashboard page URL
        window.location.href = '/dashboard';
    }
};



    useEffect(() => {
        const numbers = generateRandomNumbers();
        initPuzzle(numbers);
        startTimer();
    }, []);
    useEffect(() => {
        
      if (isSolved() && clickCount > 0) {
          clearInterval(intervalRef.current);
          alert(`Congratulations! You solved the puzzle in ${clickCount} clicks and ${timeElapsed} seconds.`);
          window.location.reload()
      } else if (timeElapsed >= timeLimit) {
          clearInterval(intervalRef.current);
          alert(`Time's up! You exceeded the time limit of ${timeLimit} seconds.`);
          window.location.reload()
        //   restartGame();



//restart the game here

      }
  }, [clickCount, timeElapsed]);
  
  const generateRandomNumbers = () => {
    const excludedNumbers = [ 20, 30, 40, 50, 60, 70, 80, 90];
    const numbers = [];
    const min = 11;
    const max = 99;
    while (numbers.length < size * size - 1) {
        const num = Math.floor(Math.random() * (max - min + 1)) + min;
        if (!excludedNumbers.includes(num) && !numbers.includes(num)) {
            numbers.push(num);
        }
    }
    numbers.push(null); // Add null for the empty cell
    return numbers;
};


    const initPuzzle = (numbers) => {
        numbers.sort(() => Math.random() - 0.5);

        const newGrid = [];
        for (let i = 0; i < size * size; i++) {
            const row = Math.floor(i / size);
            const col = i % size;
            newGrid.push({ number: numbers[i], row, col });
            if (numbers[i] === null) {
                setEmptyCell({ row, col });
            }
        }
        setGrid(newGrid);
    };

    const startTimer = () => {
        setTimerRunning(true);
    };

    useEffect(() => {
        if (timerRunning) {
            intervalRef.current = setInterval(() => {
                setTimeElapsed((prevTime) => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(intervalRef.current);
        }
        return () => clearInterval(intervalRef.current);
    }, [timerRunning]);

    // const isSolved = () => {
        
    //     return grid.every(({ number }, index) => {
            
    //         const row = Math.floor(index / size);
    //         const col = index % size;
    //         console.log(grid);
    //         return number === null || number === row * size + col + 1;
            
    //     });
    // };

    // const isSolved = () => {
    //     const sortedNumbers = grid
    //         .filter(({ number }) => number !== null) // Filter out null tiles
    //         .map(({ number }) => number); // Extract numbers
    
    //     // Check if the numbers are sorted in ascending order
    //     return sortedNumbers.every((number, index) => index === 0 || number === sortedNumbers[index - 1] + 1);
       
    // };
    const isSolved = () => {
        const sortedNumbers = grid
            .filter(({ number }) => number !== null) // Filter out null tiles
            .map(({ number }) => number); // Extract numbers
    
        // Check if the numbers form a sequence
        for (let i = 1; i < sortedNumbers.length; i++) {
            // Check if the current number is greater than or equal to the previous one
            if (sortedNumbers[i] < sortedNumbers[i - 1]) {
                return false; // If the sequence condition is violated, return false
            }
        }
    
        // Check if the grid array is not empty
        if (grid.length === 0) {
            return false; // If the grid array is empty, return false
        }
    
        // Check if the last element is null
        if (grid[grid.length - 1].number !== null) {
            return false; // If the last element is not null, return false
        }
    
        return true; // If all conditions are met, return true
    };
    
    
    

    const movePiece = (clickedCell) => {
        if (!timerRunning) {
            startTimer();
        }
        const clickedIndex = clickedCell.row * size + clickedCell.col;
        const emptyIndex = emptyCell.row * size + emptyCell.col;
        if (clickCount >= maxClicks) {
            alert('Game Over! You reached the maximum number of clicks.');
            window.location.reload()
            // restartGame();
            //return;
        }
        if ((Math.abs(clickedCell.row - emptyCell.row) === 1 && clickedCell.col === emptyCell.col) ||
            (Math.abs(clickedCell.col - emptyCell.col) === 1 && clickedCell.row === emptyCell.row)) {
            const updatedGrid = [...grid];
            [updatedGrid[clickedIndex].number, updatedGrid[emptyIndex].number] = [updatedGrid[emptyIndex].number, updatedGrid[clickedIndex].number];
            setGrid(updatedGrid);
            setEmptyCell(clickedCell);

            //updatedGrid[emptyIndex].className = "emptycell";

            
            setClickCount(clickCount + 1);
        }
    };

    const renderGrid = () => {
        return grid.map(({ number, row, col }) => (
            <PuzzlePiece
                key={`${row}-${col}`}
                number={number}
                onClick={() => movePiece({ row, col })}
            />
        ));
    };

    const restartGame = () => {
        const numbers = generateRandomNumbers();
        initPuzzle(numbers);
        setClickCount(0);
        setTimeElapsed(0);
        startTimer();
    
    };

    useEffect(() => {
        if (isSolved() && clickCount > 0) {
            clearInterval(intervalRef.current);
            alert(`Congratulations! You solved the puzzle in ${clickCount} clicks and ${timeElapsed} seconds.`);
            window.location.reload()
        } else if (timeElapsed >= timeLimit) {
            clearInterval(intervalRef.current);
            // Restart the game
            restartGame();
            // Use the newly reset values for clickCount and timeElapsed
            alert(`Time's up! You exceeded the time limit of ${timeLimit} seconds.`);
            window.location.reload()
        }
    }, [clickCount, timeElapsed]);
    return (
        // className={`puzzle-piece value-${size}`
  <div>
         <InnerBanner />
         
        <div className="puzzle-game">
           
        <div className="game-content-main">
            <div className="gc-1">
            <b>No of Clicks Pending:</b> {Number(maxClicks-clickCount)} 
            
            </div>
            <div className="gc-1">
    <b>No of Seconds Pending:</b> {Number(timeLimit-timeElapsed)}
    {/* {timeElapsed} {timeLimit} */}
            </div>
                  {/* <div className="gc-1">
                  <b>Chances left:</b> {nooflives-1} left
                  </div>
                  <div className="gc-1">
                  <b>Game Stage:</b> 1
                  </div> */}
                {/* <div className="gc-1">
                <b>Time Elapsed:</b> {timeElapsed} seconds
                </div> */}
             
            </div>
       
            <div id="puzzle-container" 
            style={{ 
            gridTemplateColumns: `repeat(${size}, 1fr)`, 
            gridTemplateRows: `repeat(${size}, 1fr)` }}
            >
                {renderGrid()}
            </div>
            <div className="info">
                
            </div>
            <div>
                    
                        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Lcvxt8pAAAAAG-gEixvHidHvY9ZxCy_A0PCxcgA" />
                        <button class="btn-3" style={{ background: '#5446d4',color: '#fff'   }}  onClick={handleReloadGane}>
      Restart Game
    </button>
                  
                </div>
            <div>
            {/* Your component JSX */}
            {/* <button onClick={handleConfirmExit}>Exit Game</button> */}
        </div>
        </div>
      </div>
    );
};

export default PuzzleGame;
